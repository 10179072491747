<template>
  <q-page class="q-pa-sm">
    <div class="row q-pa-md">
      <div class="col-md-2 q-pa-md col-xs-12 col-sm-12">
        <q-btn :to="$route.fullPath + '/addedit'"
               color="accent"
               icon="add"
               label="Добавить"
               size="sm"/>
      </div>
      <div class="col-md-10 col-xs-12 col-sm-12">

        <q-tabs
            v-model="statusFilter"
            align="justify"
            dense
        >
          <q-tab icon="done_all" label="Все" name="all">
            <q-badge color="orange" floating v-text="data.length"></q-badge>
          </q-tab>
          <q-tab icon="play_arrow" label="Активные" name="approved">
            <q-badge color="green" floating
                     v-text="data.filter(({status}) => status === 'approved').length"></q-badge>
          </q-tab>
          <q-tab icon="mode_edit" label="Черновики" name="draft">
            <q-badge color="grey" floating
                     v-text="data.filter(({status}) => status === 'draft').length"></q-badge>
          </q-tab>
          <q-tab icon="archive" label="В архиве" name="archived">
            <q-badge color="red" floating
                     v-text="data.filter(({status}) => status === 'archived').length"></q-badge>
          </q-tab>
        </q-tabs>
      </div>
    </div>

    <div class="row">
      <draggable v-model="data"
                 :component-data="{name:'fade'}"
                 animation="300"
                 item-key="opportunity_id"
                 tag="transition-group"
                 @change="positionUpdated"
      >
        <template #item="{element}">
          <div v-show="statusFilter === 'all' || statusFilter === element.status"
               class="col-auto q-ma-sm"
               style="min-width: 270px; max-width: 270px; cursor: move"
          >
            <q-card
                :bordered="element.status!=='approved'"
                :flat="element.status!=='approved'"
            >
              <q-card-section>
                <q-icon class="float-right" name="drag_indicator"/>

                <div class="ellipsis">

                  <q-icon v-if="element.status === 'approved'"
                          color="positive"
                          name="play_arrow" size="xs"/>

                  {{ element.header || '[ Без названия ]' }}
                  <q-tooltip># ID {{ element.opportunity_id }}</q-tooltip>
                </div>
                <div class="text-weight-light text-secondary ellipsis">
                  {{ element.summary || '[ Без описания ]' }}
                </div>

              </q-card-section>
              <q-separator/>
              <q-card-section class="flex flex-center">

                <div class="row full-width">
                  <div class="col-6">
                    <q-img
                        :src=element.logo_url
                        class="flex-center"
                        fit="contain"
                        spinner-color="white"
                        style="width: 100px; height: 100px;"
                    >
                      <template v-slot:error>
                        <div
                            class="absolute-full flex flex-center bg-negative text-white rounded-borders"
                            style="opacity: 0.2">
                          <q-icon name="warning" style="font-size: 4rem;"/>
                        </div>
                      </template>
                    </q-img>
                  </div>

                  <div class="col-6 text-center">
                    <q-select v-model="element.status"
                              :option-label="value => statusOptionLabels[value]"
                              :options="Object.keys(statusOptionLabels)"
                              dense
                              label="Статус"
                              @update:model-value="value => statusUpdated(element.opportunity_id, value)"/>

                    <br>

                    <q-btn :to="$route.fullPath + '/addedit/' + element.opportunity_id"
                           class="q-ma-sm" color="primary" icon="edit" round
                           size="sm">
                      <q-tooltip>Изменить</q-tooltip>
                    </q-btn>
                    <q-btn color="negative" icon="delete" round size="sm">
                      <q-tooltip>Удалить</q-tooltip>
                      <confirm-menu :ok="() => remove(element.opportunity_id)"/>
                    </q-btn>
                  </div>

                </div>

              </q-card-section>
            </q-card>
          </div>
        </template>
      </draggable>
    </div>
  </q-page>
</template>

<script>
import { OpportunityApi } from '@/api/apis/opportunity-api.ts';
import ConfirmMenu from '@/components/forms/confirmMenu';
import { Notify } from 'quasar';
import { defineComponent, ref } from 'vue';
import draggable from 'vuedraggable';

const statusOptionLabels = {
  draft: 'Черновик',
  approved: 'Активно',
  archived: 'В архиве',
};

export default defineComponent({

  components: {
    ConfirmMenu,
    draggable,
  },

  setup() {
    const data = ref([]);

    new OpportunityApi().opportunityControllerGetOpportunities().then((response) => {
      data.value = response.data.data;
    });

    const statusFilter = ref('all');
    return {
      statusFilter,
      console,
      statusOptionLabels,
      filter: ref(''),
      pagination: {
        rowsPerPage: 0,
      },
      data,

      async remove(id) {
        try {
          await new OpportunityApi().opportunityControllerDeleteOpportunity(id);
          Notify.create('Удалено');

          const index = data.value.findIndex(({ opportunity_id }) => opportunity_id === id);
          data.value.splice(index, 1);
        } catch (e) {
          console.error(e);
        }
      },

      async statusUpdated(id, status) {
        const request = { status };

        try {
          if (status !== 'approved') {
            request.list_position = null;
          }

          await new OpportunityApi().opportunityControllerUpdateOpportunity(request, id);
          Notify.create('Статус сохранен');

          await this.positionUpdated();
        } catch (e) {
          console.error(e);
        }
      },

      async positionUpdated() {
        const request = [];

        let position = 1;
        let updated = 0;
        data.value.forEach((item) => {
          if (item.status !== 'approved') {
            return;
          }

          if (item.list_position !== position) updated++;

          item.list_position = position;

          request.push({
            opportunity_id: item.opportunity_id,
            list_position: item.list_position,
          });

          position += 1;
        });

        if (!request.length || !updated) {
          return;
        }

        try {
          await new OpportunityApi().opportunityControllerUpdateOpportunitiesPositions(request);
          Notify.create('Позиция сохранена');
        } catch (e) {
          console.error(e);
        }
      },
    };
  },
});
</script>

<style>

</style>
